import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveDynamicComponent as _resolveDynamicComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent } from "vue"

const _hoisted_1 = { class: "text-2xl px-1 py-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Card = _resolveComponent("Card")!

  return (_openBlock(), _createBlock(_component_Card, {
    title: _ctx.$t('print_edition.transcription_header'),
    informationText: _ctx.transcriptionInfo
  }, {
    default: _withCtx(() => [
      _createElementVNode("p", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.transcription, (word, index) => {
          return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.makeLinks ? 'router-link' : 'span'), {
            class: _normalizeClass(["px-2 cursor-pointer inline-block", index == _ctx.selectedWord ? 'bg-green-500 text-white' : '']),
            key: index,
            onClick: ($event: any) => (_ctx.$emit('wordSelected', index)),
            to: {
          name: 'VerseConcordance',
          params: { word: index + 1 },
        }
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(word), 1)
            ]),
            _: 2
          }, 1032, ["class", "onClick", "to"]))
        }), 128))
      ])
    ]),
    _: 1
  }, 8, ["title", "informationText"]))
}